// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.iframe': {
          'justify-content': 'center',
          display: 'flex',
          'margin-bottom': '-20px',
          'margin-top': '10px'
        },
        '.hideword': {
          'width': '100%',
          'height': '20px',
          'background-color': 'white',
          'position': 'absolute'
        },
        '.react-calendar-timeline .rct-header-root': {
          background: 'white !important' 
        },
        '.react-calendar-timeline .rct-calendar-header': {
          border: 'none !important'
        },
        '.react-calendar-timeline .rct-dateHeader': {
          border: 'none !important',
          backgroundColor: 'white !important'
        }
      }}
    />
  );

  return inputGlobalStyles;
}
