import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Select, Button, ToggleButtonGroup, ToggleButton, Typography, FormControl, InputLabel, MenuItem } from '@mui/material';
// components
import { ProductList } from '../../sections/@dashboard/products';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState(false);
  const [tab, setTab] = useState(1);

  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/course-api`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setCourses(result);
          if (location.state && location.state.id)
            setCourseId(location.state.id)
          else
            setCourseId(result[0].id);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [])

  useEffect(() => {
    if (courseId)
      fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/lesson-api?sort=name&filter[edmap]=${tab}&filter[course_id]=${courseId}`, {
        crossDomain: true,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            setLessons(result);
          },
          (error) => {
            console.log(error);
          }
        )
  }, [tab, courseId])

  return (
    <>
      <Helmet>
        <title> Уроки | Lala English </title>
      </Helmet>

      <Container>

        {location.state && location.state.id ? <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button onClick={() => navigate(-1)} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
            Назад
          </Button>
        </Stack> : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Курс</InputLabel>
            <Select
              value={courseId}
              label="Курс"
              onChange={(event) => setCourseId(event.target.value)}
            >
              {courses.map((value) => {
                return <MenuItem key={value.id} value={value.id}>{value.name} {value.level?'Classic':'Base'}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(_, value) => setTab(value)}
          >
            <ToggleButton value={1}>
              <Typography>Ed Map 1</Typography>
            </ToggleButton>
            <ToggleButton value={2}>
              <Typography>Ed Map 2</Typography>
            </ToggleButton>
            <ToggleButton value={3}>
              <Typography>Ed Map 3</Typography>
            </ToggleButton>
            <ToggleButton value={4}>
              <Typography>Ed Map 4</Typography>
            </ToggleButton>
            <ToggleButton value={5}>
              <Typography>Ed Map 5</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <ProductList lessons={lessons} />
      </Container>
    </>
  );
}
