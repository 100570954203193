import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  Card,
  Button,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import moment from 'moment'
import Timeline, { TimelineHeaders, DateHeader } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { CreateReport } from 'src/sections/@dashboard/student';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

import 'moment/locale/uk';

moment().locale('uk');

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: "Ім'я", alignRight: false },
  { id: 'company', label: 'Email', alignRight: false },
  { id: 'role', label: 'Телефон', alignRight: false },
  { id: 'isVerified', label: 'Група', alignRight: false },
  /* { id: 'status', label: 'Status', alignRight: false }, */
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [students, setStudents] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [tab, setTab] = useState('students');

  const [items, setItems] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/student-api?filter[sgroup_id]=${location.state.id}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setStudents(result.map((item) => {
            item.title = item.name;
            return item;
          }));
          console.log(students);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [])

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
    console.log('selected', newSelected)
  };

  const handleSave = () => {
    setItems(selected.map((value, index) => {
      return {
        id: index,
        group: value,
        title: '',
        start_time: moment().startOf('day').add(4, 'hour'),
        end_time: moment().startOf('day').add(21, 'hour')
      }
    }));
    setSelected([]);
    setTab('activities')
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredUsers = applySortFilter(students, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Студенти | Lala English </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button onClick={() => navigate(-1)} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
            Назад
          </Button>
          <Typography variant="h4" gutterBottom>
            Група "{location.state.name}":
          </Typography>
          <Button disabled={!selected.length || tab !== 'students'} onClick={handleSave} variant="contained" startIcon={<Iconify icon="eva:save-fill" />}>
            Зберегти відвідуваність
          </Button>
          <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
            <DialogTitle>
              <Typography>Звіт про урок</Typography>
            </DialogTitle>
            <DialogContent>
              <CreateReport success={() => setIsOpen(false)} />
            </DialogContent>
          </Dialog>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(_, value) => setTab(value)}
          >
            <ToggleButton value="students">
              <Typography>Студенти</Typography>
            </ToggleButton>
            <ToggleButton value="activities">
              <Typography>Відвідуваність</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {tab === 'students' ?
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={students.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, phone, email } = row;
                      const selectedUser = selected.indexOf(id) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={'/assets/images/avatars/avatar_2.jpg'} />
                              <Link
                                component="button"
                                variant="subtitle2"
                                color="#333"
                                sx={{ textAlign: 'left' }}
                                onClick={() => navigate('/teacher/profile', { state: row })}
                              >
                                {name}
                              </Link>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">{phone}</TableCell>

                          <TableCell align="left">Dream Big</TableCell>

                          {/*  <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={students.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card> :
          <Card>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Timeline
                groups={students}
                items={items}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={0.75}
                showCursorLine
                canMove={false}
                canResize={false}
                minZoom={31 * 24 * 60 * 60 * 1000}
                maxZoom={31 * 24 * 60 * 60 * 1000}
                defaultTimeStart={moment()
                  .startOf('month')
                  .toDate()}
                defaultTimeEnd={moment()
                  .startOf('month')
                  .add(31, 'day')
                  .toDate()}
              ><TimelineHeaders className="sticky"><DateHeader unit='day' /></TimelineHeaders></Timeline>
            </Stack>
          </Card>
        }
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Редагувати
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Видалити
        </MenuItem>
      </Popover>
    </>
  );
}
