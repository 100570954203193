import PropTypes from 'prop-types';

import { useState } from 'react';
import profile from 'src/store/profile';
// @mui
import { Stack, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

CreateFeedback.propTypes = {
    success: PropTypes.func.isRequired,
};

export default function CreateFeedback({ student, success }) {
    const [text, setText] = useState('');
    const [error, setError] = useState('');

    const handleClick = () => {
        if (text !== '')
            fetch("https://admin.lalaenglish.com.ua/index.php/feedback-api/create", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify({ text, teacher_id: profile.data.id, student_id: student.id, email: student.email, course_id: 1 })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.id)
                            success();
                        else if (result.status === "error")
                            setError(result.message)
                        console.log(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        else setError('true')
    };

    return (
        <>
            <Stack spacing={3}>

                <TextField multiline rows={5} name="text" label="Введіть текст" value={text} error={Boolean(error)}
                    onChange={(event) => {
                        setText(event.target.value);
                    }} />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                    Додати
                </LoadingButton>
            </Stack>
        </>
    );
}
