// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Інструкції',
    path: '/teacher/intro',
    icon: icon('ic_lock'),
  },
  {
    title: 'Курси',
    path: '/teacher/courses',
    icon: icon('ic_cart'),
  },
  {
    title: 'Матеріали',
    path: '/teacher/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Групи студентів',
    path: '/teacher/groups',
    icon: icon('ic_user'),
  },
  {
    title: 'Розклад',
    path: '/teacher/calendar',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Lala info',
    path: '/teacher/info',
    icon: icon('ic_lock'),
  }
  /* {
    title: 'Demo. Картки',
    path: '/demo/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'Demo. Статистика',
    path: '/demo/app',
    icon: icon('ic_analytics'),
  }, */
];

export default navConfig;
