import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import profile from 'src/store/profile';
// @mui
import {
  Card, Container, Grid, CardContent, Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Stack
} from '@mui/material';
// components
import YouTube from 'react-youtube';
import { AddVideoForm } from 'src/sections/@dashboard/student';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function MediaPage() {

  const [videos, setVideos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const opts = {
    height: '200',
    width: '360',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const getVideos = () => {
    setIsOpen(false)
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/video-api?filter[type]=2&filter[student_id]=${profile.data.id}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setVideos(result);
        },
        (error) => {
          console.log(error);
        }
      )
  }

  useEffect(() => {
    getVideos()
  }, [])

  return (
    <>
      <Helmet>
        <title> Мої медіа | Lala English </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button onClick={() => setIsOpen(true)} variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />}>Додати відео</Button>
        </Stack>
        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
          <DialogTitle>
            {" "}
            <Typography>Додати відео</Typography>
          </DialogTitle>
          <DialogContent>
            <AddVideoForm success={getVideos} />
          </DialogContent>
        </Dialog>
        <Grid container spacing={3}>
          {videos.map((video) => (
            <Grid key={video.id} item xs={12} sm={6} md={4}>
              <Card sx={{ position: 'relative' }}>
                <YouTube videoId={video.url} opts={opts} onReady={_onReady} />

                <CardContent sx={{ pt: 2 }}>
                  <Typography variant="subtitle2">{video.title}</Typography>
                  {video.text ? <Typography variant="body2" sx={{ pt: 2 }}>{video.text}</Typography> : null}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
