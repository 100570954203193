import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import profile from 'src/store/profile';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginFormST() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleClick = () => {
    if (username !== '' && password !== '')
      fetch("https://admin.lalaenglish.com.ua/index.php/auth/loginstud", {
        crossDomain: true,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ username, password })
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            if (result.status === "success") {
              if(result.data.course_id != null && result.data.group_id != null)
                profile.setFullAccess()
              else if(result.data.selfstudy === 1)
                profile.setSelfstudyAccess();
              profile.setStudentRole();
              profile.login(result.data);
              /* alert(JSON.stringify(profile.data)); */
              navigate('/student/profile');
            }
            else if (result.status === "error")
              setError(result.message)
          },
          (error) => {
            console.log(error);
          }
        )
    else setError('true')
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="username" label="Логін" value={username} error={Boolean(error)}
          onChange={(event) => {
            setUsername(event.target.value);
          }} />

        <TextField
          name="password"
          label="Пароль"
          type={showPassword ? 'text' : 'password'} error={Boolean(error)}
          helperText={"Неправильний логін або пароль"}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Запам'ятати" />
        <Link variant="subtitle2" underline="hover">
          Відновити пароль
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Увійти
      </LoadingButton>
    </>
  );
}
