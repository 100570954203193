import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import profile from 'src/store/profile';
// @mui
import {
  Grid, Container,
  Snackbar, Alert, Card, CardContent, CardHeader
} from '@mui/material';
import { CreateMessage } from 'src/sections/@dashboard/student';
import { MessageNews } from 'src/sections/@dashboard/app';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function DayPage() {
  const location = useLocation();

  const [alert, setAlert] = useState(false);
  const [messages, setMessages] = useState([]);
  const [student] = useState(profile.isTeacher ? location.state : profile.data)

  const getMessages = () => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/message-api?sort=-id&filter[type]=3&filter[student_id]=${student.id}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setMessages(result);
        },
        (error) => {
          console.log(error);
        }
      )
  }

  useEffect(() => {
    getMessages()
  }, [])
  return (
    <>
      <Helmet>
        <title> Щоденник | Lala English </title>
      </Helmet>

      <Container maxWidth="xl">

        <Grid container spacing={3} sx={{ 'flex-direction': 'row-reverse'}}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Новий запис" />

              <CardContent>
                <CreateMessage type={3} student={student} success={() => {
                  getMessages();
                  setAlert(true)
                }} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <MessageNews
              title=""
              list={messages.map((message, index) => ({
                id: message.id,
                title: message.date,
                description: message.text,
                image: '/assets/images/avatars/avatar_default.jpg',
                postedAt: "",
              }))}
            />
          </Grid>
          <Snackbar open={alert} autoHideDuration={4000} onClose={() => setAlert(false)}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              Успішно відправлено!
            </Alert>
          </Snackbar>
        </Grid>
      </Container>
    </>
  );
}
