// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfigSTFULL = [
  {
    title: 'Профіль',
    path: '/student/profile',
    icon: icon('ic_user'),
  },
  {
    title: 'Правила',
    path: '/student/intro',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Уроки',
    path: '/student/homeworks',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Мій щоденник',
    path: '/student/day',
    icon: icon('ic_user'),
  },
  {
    title: 'Мої завантаження',
    path: '/student/media',
    icon: icon('ic_cart'),
  },
  {
    title: 'Lala info',
    path: '/student/info',
    icon: icon('ic_lock'),
  },
];

export default navConfigSTFULL;
