import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import profile from 'src/store/profile';
// @mui
import { Container, Stack, ToggleButton, ToggleButtonGroup, Typography, Grid, Card, CardHeader, CardContent, Stepper, Step, Box, Button, StepLabel, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
// components
import { CreateMessage } from 'src/sections/@dashboard/student';

// ----------------------------------------------------------------------

export default function Homeworks() {
  const [tab, setTab] = useState(1);
  const [check, setCheck] = useState(0);
  const [lessons, setLessons] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/lesson-api?sort=name&filter[edmap]=${tab}&filter[course_id]=${profile.data.course_id}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('fetch edmap'.tab, result);
          setLessons(result);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [tab])

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  })

  const Iframe = props => {
    return (<div className="iframe" dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
  }

  const handleBlur = () => {
    console.log("document blurred", document.activeElement);
  }

  const handleFocus = () => {
    console.log("document focused", document.activeElement);
  }

  return (
    <>
      <Helmet>
        <title> Уроки | Lala English </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FormControl sx={{width: '300px'}}>
            <InputLabel id="demo-simple-select-label">Урок</InputLabel>
            <Select
              value={check}
              label="Урок"
              onChange={(event) => setCheck(event.target.value)}
            >
              {lessons.map((value, index) => {
                return <MenuItem key={value.id} value={index}>{value.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(_, value) => setTab(value)}
          >
            <ToggleButton value={1}>
              <Typography>Ed Map 1</Typography>
            </ToggleButton>
            <ToggleButton value={2}>
              <Typography>Ed Map 2</Typography>
            </ToggleButton>
            <ToggleButton value={3}>
              <Typography>Ed Map 3</Typography>
            </ToggleButton>
            <ToggleButton value={4}>
              <Typography>Ed Map 4</Typography>
            </ToggleButton>
            <ToggleButton value={5}>
              <Typography>Ed Map 5</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6} spacing={1}>
            {lessons.length ?
              <Card sx={{ height: '530px' }}>
                <CardHeader title={`Завдання № ${activeStep + 1}`} />
                {lessons[check].student_material && activeStep === 0 ?
                  <Iframe iframe={lessons[check].student_material} /> :
                 lessons[check].student_material2 && activeStep === 1 ?
                  <Iframe iframe={lessons[check].student_material2} /> :
                 lessons[check].student_material3 && activeStep === 2 ?
                  <Iframe iframe={lessons[check].student_material3} /> :
                 lessons[check].student_material4 && activeStep === 3 ?
                  <Iframe iframe={lessons[check].student_material4} /> :
                 lessons[check].student_material5 && activeStep === 4 ?
                  <Iframe iframe={lessons[check].student_material5} /> :
                  <Typography
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      fontSize: 14,
                      marginLeft: '24px',
                      lineHeight: '5'
                    }}
                  >В цьому уроці немає завдань</Typography>}
                <div className='hideword' />
                <Stepper activeStep={activeStep} sx={{ marginTop: '10px', padding: '10px' }}>
                  {[...Array(5)].map((_, index) => <Step key={index}><StepLabel /></Step>)}
                </Stepper>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep(activeStep - 1)}
                    sx={{ mr: 1 }}
                  >
                    Назад
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button disabled={activeStep === 4} onClick={() => setActiveStep(activeStep + 1)} sx={{ mr: 1 }}>
                    Наступне завдання
                  </Button>
                </Box>
              </Card>
              : null}
          </Grid>
          {lessons.length && lessons[check].image ?
          <Grid item xs={12} md={6} lg={6} spacing={1}>
              <Card sx={{paddingBottom:'20px'}}>
                <CardHeader title={lessons[check].task} />
                <Box m={'20px'} mt={1} mb={0}>
                  <img src={lessons[check].image} alt='' />
                </Box>
              </Card>
              <Card sx={{mt: 1}}>
              <CardContent>
                <CreateMessage type={0} student={profile.data} offlink success={() => {
                  setAlert(true)
                }} />
              </CardContent>
            </Card>
          </Grid>
          : null}
          {lessons.length && lessons[check].text ?
          <Grid item xs={12} md={6} lg={6} spacing={1}>
              <Card sx={{paddingBottom:'20px'}}>
                <CardHeader title='Текст для словника' />
                <Typography
                    sx={{
                      fontSize: 14,
                      marginLeft: '24px',
                      marginTop: '10px'
                    }}
                  ><div style={{whiteSpace: "pre-wrap"}}>{lessons[check].text}</div></Typography>
              </Card>
          </Grid>
          : null}
        </Grid>
      </Container>
    </>
  );
}
