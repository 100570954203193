import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Divider, Card, Link, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

ShopCourseCard.propTypes = {
  course: PropTypes.object,
};

export default function ShopCourseCard({ course }) {
  const { name, level, price, info, hours, duration, link } = course;
  const ll = course.lesson_length;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/teacher/products', { state: course });
  };

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover" onClick={handleClick} component="button">
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: 20,
            }}
            noWrap
          >
            {name} {level ? 'Classic' : 'Base'}
          </Typography>
        </Link>

        <Typography
          component="span"
          sx={{
            color: 'text.secondary',
            fontSize: 14,
          }}
        >
          {info}
        </Typography>

        {link ?
          <a href={link} target="_blank" rel="noreferrer">
            Презентація
          </a> : null}

        <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

        <Grid container>
          <Grid item xs={6}>
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                Тривалість
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                Містить
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                1 Edmap
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                1 заняття
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                На місяць
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                Ціна/міс.
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                Домашнє
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                Уроки ігри
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                {duration} місяців
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                5 Edmaps
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                2 місяці
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                {ll} год.
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                {hours} годин
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                {price} грн.
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                component="span"
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                +включене
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid item>
              <Typography
                /* component="span" */
                sx={{
                  color: 'text.secondary',
                  fontSize: 14,
                }}
              >
                +включене
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
