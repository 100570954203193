import { makeAutoObservable, autorun } from 'mobx';

class Profile {
  isTeacher = false;

  role = 0;

  access = 0;

  data = {};

  avatar = '/assets/images/avatars/avatar_default.jpg';

  constructor() {
    makeAutoObservable(this);

    const storedJson = localStorage.getItem('LalaStore');
    if (storedJson) Object.assign(this, JSON.parse(storedJson));
    autorun(() => {
      localStorage.setItem('LalaStore', JSON.stringify(this));
    });
  }

  setTeacherRole() {
    this.isTeacher = true;
    this.role = 2;
  }

  setStudentRole() {
    this.isTeacher = false;
    this.role = 1;
  }

  setAvatar(img) {
    this.avatar = img;
  }

  setSelfstudyAccess() {
    this.access = 1;
  }

  setFullAccess() {
    this.access = 2;
  }

  login(data) {
    this.data = data;
  }

  logout() {
    localStorage.removeItem('LalaStore');
    this.isTeacher = false;
    this.data = {};
    this.role = 0;
  }
}

export default new Profile();
