import PropTypes from 'prop-types';

import { useState } from 'react';
// @mui
import { Stack, Select, MenuItem, Grid, Typography, FormControl, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

CreateReport.propTypes = {
    success: PropTypes.func.isRequired,
};

export default function CreateReport({ success }) {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');

    const handleClick = () => {
        if (title !== '' && url !== '')
            fetch("https://admin.lalaenglish.com.ua/index.php/video-api/create", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify({ title, url, type: 1 })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.id)
                            success();
                        else if (result.status === "error")
                            setError(result.message)
                        console.log(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        else setError('true')
    };

    return (
        <>
            <Stack>
                <Divider sx={{ mb: 3 }} />
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                select
                                fullWidth
                                value={title}
                                label="Група"
                                onChange={(event) => {
                                    setTitle(event.target.value);
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} sx={{ mb: '10px' }}>
                            <Typography>Оберіть матеріали, які використовували на уроці:</Typography>
                        </Grid>

                        <Grid container xs={12} md={12} lg={12} rowSpacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                select
                                    // labelId="demo-simple-select-label"
                                    // id="demo-simple-select"
                                    name="title"
                                    fullWidth
                                    value={title}
                                    label="Група"
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                select
                                    // labelId="demo-simple-select-label"
                                    // id="demo-simple-select"
                                    name="title"
                                    fullWidth
                                    value={title}
                                    label="Група"
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                select
                                    // labelId="demo-simple-select-label"
                                    // id="demo-simple-select"
                                    name="title"
                                    fullWidth
                                    value={title}
                                    label="Група"
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                select
                                    // labelId="demo-simple-select-label"
                                    // id="demo-simple-select"
                                    name="title"
                                    fullWidth
                                    value={title}
                                    label="Група"
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                                Додати
                            </LoadingButton>
                        </Grid>
                    </Grid>

            </Stack>
        </>
    );
}
