import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Card, Container, Grid, CardContent, Typography } from '@mui/material';
// components
import YouTube from 'react-youtube';

// ----------------------------------------------------------------------

export default function IntroStudPage() {

  const [videos, setVideos] = useState([]);

  const opts = {
    height: '200',
    width: '360',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  useEffect(() => {
    fetch("https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/video-api?filter[type]=1", {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setVideos(result);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [])

  return (
    <>
      <Helmet>
        <title> Правила | Lala English </title>
      </Helmet>

      <Container>

        <Grid container spacing={3}>
          {!videos.length ? <Typography variant="body2" sx={{ p: 3.5 }}>Ще немає відео</Typography> : null}
          {videos.map((video) => (
            <Grid key={video.id} item xs={12} sm={6} md={4}>
              <Card sx={{ position: 'relative' }}>
                <YouTube videoId={video.url} opts={opts} onReady={_onReady} />

                <CardContent sx={{ pt: 2 }}>
                  <Typography variant="subtitle2">{video.title}</Typography>
                  {video.text ? <Typography variant="body2" sx={{ pt: 2 }}>{video.text}</Typography> : null}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
