import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, info } = product;
  const link = product.teacher_material;

  return (
    <Card>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" sx={{
            fontSize: 20,
          }} noWrap>
            {name}
          </Typography>
        </Link>

        <Typography
          component="span"
          sx={{
            color: 'text.secondary',
            fontSize: 14,
          }}
        >
          Опис: {info}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link href={link} target="_blank">
            Презентація
          </Link>
        </Stack>
      </Stack>
    </Card>
  );
}
