import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Container, Stack, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
// components
import { CourseList } from '../../sections/@dashboard/courses';
/* // mock
import PRODUCTS from '../../_mock/courses'; */

// ----------------------------------------------------------------------

export default function CoursesPage() {
  const [courses, setCourse] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/course-api?filter[level]=${tab}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setCourse(result);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [tab])

  return (
    <>
      <Helmet>
        <title> Курси | Lala English </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(_, value) => setTab(value)}
          >
            <ToggleButton value={0}>
              <Typography>Base</Typography>
            </ToggleButton>
            <ToggleButton value={1}>
              <Typography>Classic</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <CourseList courses={courses} />
      </Container>
    </>
  );
}
