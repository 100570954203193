import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ lessons, ...other }) {
  return (
    <Grid container {...other}>
      <Grid container xs={12} md={6} lg={12} spacing={3}>
        {lessons.map((product, index) => (
          <Grid key={index} item xs={6} sm={6} md={3}>
            <ShopProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
