import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginFormST } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPageST() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Online Lala English </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Box
              component="div"
              sx={{
                height: 50,
                display: 'inline-flex',
                mb: 5,
              }}
            >
              <img src={'/assets/images/prizes/faq__top-animation.svg'} alt={'lala english logo'} />
            </Box>
            <Typography variant="h3" gutterBottom>
              Вхід для студента
            </Typography>
            <Typography variant="h6" gutterBottom>
              Онлайн-платформа Lala English
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Немає акаунта? {''}
              <Link variant="subtitle2">Зареєструватись</Link>
            </Typography>

            <LoginFormST />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
