import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import profile from 'src/store/profile';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Card, Stack } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

// ----------------------------------------------------------------------

export default function CalendarPage() {
  const [groups, setGroups] = useState([]);

  const navigate = useNavigate();

  const handleEventClick = ({ event }) => {
    navigate('/teacher/user', { state: { ...event.extendedProps, id: event.id } })
  }

  useEffect(() => {
    fetch(`https://lala.kokosova.com/admin.lalaenglish.com.ua/index.php/sgroup-api?filter[teacher_id]=${profile.data.id}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setGroups(result);
          setGroups(result.map((item) => {
            item.title = item.name;
            item.startRecur = item.date_start;
            item.endRecur = item.date_end;
            item.daysOfWeek = [item.first_day, item.second_day];
            return item;
          }));
        },
        (error) => {
          console.log(error);
        }
      )
  }, [])
  return (
    <>
      <Helmet>
        <title> Розклад | Lala English </title>
      </Helmet>

      <Container>
        <Card>
          <Stack spacing={2} sx={{ p: 3 }}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              eventClick={handleEventClick}
              initialView="dayGridMonth"
              weekends
              events={groups}
              locale='uk'
              buttonText={{
                today: 'Сьогодні'
              }}
            />
          </Stack>
        </Card>
      </Container>
    </>
  );
}
