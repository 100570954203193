import PropTypes from 'prop-types';

import { useState } from 'react';
import profile from 'src/store/profile';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

CreateMessage.propTypes = {
  success: PropTypes.func.isRequired,
};

export default function CreateMessage({ student, success, type, groupid, offlink }) {
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const [errorLink, setErrorLink] = useState(false);

  const handleClick = () => {
    if (link === '' || (link.startsWith('http') && link.length > 10))
      if (text !== '')
        fetch('https://admin.lalaenglish.com.ua/index.php/message-api/create', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            text,
            link,
            type,
            teacher_id: profile.data.id,
            student_id: type === 2 ? 1 : student.id,
            group_id: type !== 2 ? 1 : groupid,
            lesson_id: 1,
          }),
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.id) {
                success();
                setText('');
                setLink('');
              } else if (result.status === 'error') setError(result.message);
              console.log(result);
            },
            (error) => {
              console.log(error);
            }
          );
      else setError('true');
    else setErrorLink(true);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          multiline
          rows={4}
          name="text"
          label="Введіть текст"
          value={text}
          error={Boolean(error)}
          onChange={(event) => {
            setText(event.target.value);
          }}
        />

        {!offlink ? (
          <TextField
            name="link"
            label="Посилання http://"
            value={link}
            error={errorLink}
            onChange={(event) => {
              setLink(event.target.value);
            }}
          />
        ) : null}

        <LoadingButton type="submit" variant="outlined" onClick={handleClick}>
          Надіслати
        </LoadingButton>
      </Stack>
    </>
  );
}
